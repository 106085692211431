/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { promotionText } from '../../../core/Constants';
import { getImageURL, getHostCURL } from '../../../common/utils/CardUtils';
import './PromotionSection.scss';
import { Typography } from '~/@one-thd/sui-atomic-components';
import { WelcomeCardTile } from '../NotificationSection/Notifications/WelcomeCardTile';

const PreferredPricingPromo = () => {
  return (
    <a
      className="PromotionSection__moreBox"
      data-testid=""
      href={getHostCURL('/myaccount/proXtraPricing')}
      rel=" noreferrer"
    >
      <div className="col__10-12">
        <div>{promotionText.PreferredPricingPromo}</div>
        <div className="PromotionSection__subLink">
          <span>Learn More</span>
        </div>
      </div>
      <div className="col__2-12 PromotionSection__closerGetting">
        <Image
          width="38"
          height="34"
          src={getImageURL('ProTiers/cardIcon-PreferredPricing.svg')}
          alt="PP_RGB_Orange-Black"
          className="PromotionSection__linkIcon"
        />
      </div>
    </a>
  );
};

const VolumePricingPromo = () => {
  return (
    <WelcomeCardTile
      imageURL="ProTiers/cardIcon-VPP-black.svg"
      altText="volume-pricing"
      refLink="/myaccount/dashboard"
      linkText="Learn More"
    >
      <Typography variant="body-base" weight="regular" height="normal">
        {promotionText.VolumePricingPromo}
      </Typography>
    </WelcomeCardTile>
  );
};

const PlccAcquisitionPromo = ({ activePxccFactor }) => {
  const spendMultiplier = activePxccFactor || 4;
  return (
    <a
      className="PromotionSection__moreBox"
      data-testid=""
      href={getHostCURL('/c/credit-center#RevolvingChargeCard')}
      rel=" noreferrer"
    >
      <div className="col__9-12">
        <div>{promotionText.PlccAcquisitionPromo}</div>
        <div className="PromotionSection__subLink">
          <span>Earn Perks {spendMultiplier}X Faster</span>
        </div>
      </div>
      <div className="col__3-12 PromotionSection__arrowMain">
        <Image className="cardImage" src={getImageURL('plcc-angled.svg')} alt="plcc-angled" />
      </div>
    </a>
  );
};

const TextToConfirmPromo = () => {
  return (
    <a
      className="PromotionSection__moreBox"
      data-testid=""
      href={getHostCURL('/myaccount/payments')}
      rel=" noreferrer"
    >
      <div className="col__10-12">
        <div>{promotionText.TextToConfirmPromo}</div>
        <div className="PromotionSection__subLink">
          <span>Learn About Text2Confirm</span>
        </div>
      </div>
      <div className="col__2-12 PromotionSection__closerGetting">
        <Image
          width="35px"
          height="35px"
          src={getImageURL('PrgDetails/icons-Text2Confirm@2x.png')}
          alt="text-confirm"
          className="PromotionSection__linkIcon"
        />
      </div>
    </a>
  );
};

const PaintProductsPromo = () => {
  return (
    <a className="PromotionSection__moreBox" data-testid="" href="" rel=" noreferrer">
      <div className="col__10-12">
        <div>{promotionText.PaintProductsPromo}</div>
        <div className="PromotionSection__subLink">
          <span>View Details</span>
        </div>
      </div>
      <div className="col__2-12 PromotionSection__closerGetting">
        <Image
          width="28.08px"
          height="36px"
          src={getImageURL('PrgDetails/Group 26@2x.png')}
          alt="icon-PaintRewards"
          className="PromotionSection__linkIcon"
        />
      </div>
    </a>
  );
};

export const PLCCPurchaser = () => {
  return (
    <WelcomeCardTile
      imageURL="WelcomeCard/active_perks_PLCC.svg"
      altText="Credit Card Promo"
      refLink="/b2b/account/view/thdcreditcard"
      linkText="Learn More"
    >
      <Typography variant="body-base" weight="regular" height="normal">
        New Pro Xtra Credit Card
      </Typography>
    </WelcomeCardTile>
  );
};

const ProXtraGeneralKnowledgePromo = () => {
  return (
    <a
      className="PromotionSection__moreBox"
      data-testid=""
      href={getHostCURL('/myaccount/proXtraRewards')}
      rel=" noreferrer"
    >
      <div className="col__10-12">
        <div>{promotionText.ProXtraGeneralKnowledgePromo}</div>
        <div className="PromotionSection__subLink">
          <span>Learn More</span>
        </div>
      </div>
      <div className="col__2-12 PromotionSection__closerGetting">
        <Image
          width="36px"
          height="37px"
          src={getImageURL('Pro-Xtra_Perk_Icon-whiteFill.svg')}
          alt="pro-extra-perk"
          className="PromotionSection__linkIcon"
        />
      </div>
    </a>
  );
};

const ManageProjectMore = () => {
  return (
    <a className="PromotionSection__moreBox" data-testid="" href="" rel=" noreferrer">
      <div className="col__10-12">
        <div>{promotionText.ManageProjectMore}</div>
        <div className="PromotionSection__subLink">
          <span>Learn More</span>
        </div>
      </div>
      <div className="col__2-12 PromotionSection__arrowMain">
        <Image
          width="54px"
          height="39px"
          src={getImageURL('plcc-angled.svg')}
          alt="plcc-angled"
          className="PromotionSection__linkIcon"
        />
      </div>
    </a>
  );
};

const RegisteredTender = () => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  return (
    <WelcomeCardTile
      imageURL="RegisteredTender.svg"
      imageClass="registered-tender"
      altText="registered-tender"
      refLink="/myaccount/payments"
      linkText="Get Easy Purchase Tracking"
    >
      <Typography variant="body-base" weight="regular" height="normal">
        {promotionText.RegisteredTender}
      </Typography>
    </WelcomeCardTile>
  );
};

const AppAwarenessPromo = () => {
  return (
    <WelcomeCardTile
      imageURL="appAwarenessIcon.svg"
      altText="app-awareness"
      refLink="/c/SF_Mobile_Shopping"
      linkText="Explore the App"
    >
      <Typography variant="body-base" weight="regular" height="normal">
        {promotionText.AppAwarenessPromo}
      </Typography>
    </WelcomeCardTile>
  );
};

const ProOnlineExperiencePromo = () => {
  return (
    <WelcomeCardTile
      imageURL="WelcomeCard/active_BusinessTools.svg"
      altText="pro-online-experience"
      refLink="/c/overview_pro_onboarding"
      linkText="Learn More"
    >
      <Typography variant="body-base" weight="regular" height="normal">
        {promotionText.ProOnlineExperiencePromo}
      </Typography>
    </WelcomeCardTile>
  );
};

const RenderPromotionTiles = ({ tiles }) => {
  return <div> {tiles}</div>;
};

export const showingTilePromotions = ({ isAdmin, userData }) => {
  const { preferredPricingTierZero } = userData?.customerInfo || {};
  const { pxccUserCategory } = userData?.perkSummary || {};
  const showingTile = {
    RegisteredTender: true,
    PreferredPricingPromo: preferredPricingTierZero,
    PLCCPurchaser: false,
    PlccAcquisitionPromo: isAdmin && pxccUserCategory === 'Targeted',
    AppAwarenessPromo: true,
    ProOnlineExperiencePromo: true,
    VolumePricingPromo: true,
    TextToConfirmPromo: isAdmin,
    ProXtraGeneralKnowledgePromo: isAdmin,
    PaintProductsPromo: false,
    ManageProjectMore: false,
  };
  return showingTile;
};

export const prioritizedListPromotions = ({ showingTile, userData }) => {
  const { activePxccFactor } = userData?.perkSummary || {};
  const prioritizedList = [
    showingTile.RegisteredTender && <RegisteredTender />,
    showingTile.PreferredPricingPromo && <PreferredPricingPromo />,
    showingTile.PLCCPurchaser && <PLCCPurchaser />,
    showingTile.PlccAcquisitionPromo && (
      <PlccAcquisitionPromo activePxccFactor={activePxccFactor} />
    ),
    showingTile.AppAwarenessPromo && <AppAwarenessPromo />,
    showingTile.ProOnlineExperiencePromo && <ProOnlineExperiencePromo />,
    showingTile.VolumePricingPromo && <VolumePricingPromo />,
    showingTile.TextToConfirmPromo && <TextToConfirmPromo />,
    showingTile.ProXtraGeneralKnowledgePromo && <ProXtraGeneralKnowledgePromo />,
    showingTile.PaintProductsPromo && <PaintProductsPromo />,
    showingTile.ManageProjectMore && <ManageProjectMore />,
  ];
  return prioritizedList;
};

const PromotionSection = ({ tiles }) => {
  const DisplayTiles = () => tiles;

  return <DisplayTiles />;
};

export const exportsForTesting = {
  RegisteredTender,
  PreferredPricingPromo,
  PlccAcquisitionPromo,
  AppAwarenessPromo,
  ProOnlineExperiencePromo,
  VolumePricingPromo,
  TextToConfirmPromo,
  ProXtraGeneralKnowledgePromo,
  PaintProductsPromo,
  ManageProjectMore,
  RenderPromotionTiles,
};

export default PromotionSection;
