import * as React from 'react';
import { Alert, Typography, DrawerHeader, DrawerBody } from '@one-thd/sui-atomic-components';
import { LoadingPacDrawer } from '../../../common/Drawer/PacDrawer/PacDrawer';
import { PacDrawerContext } from '../../../contexts/PacDrawerContext';
import { useFetchActivatePerks } from '../../../hooks';
import { windowExists } from '../../../common/utils/AccountUtils';
import { incrementPerkCounter, qualtricsRefresh } from '../../../common/utils/DrawerUtils';
import { RewardBarcodeDrawer } from '../RewardBarcodeDrawer';
import { SELECTION_CONSTANTS as CONSTANTS } from '../../../core/DrawerConstants';

export const ErrorSelectionDrawer = (props) => {
  // TODO: [Kips] Pass in title, header, and icon based on .earned
  const { setOpen, infoPerks, eventOutput } = React.useContext(PacDrawerContext) ?? {};
  const { refreshFn, eventOutput: eventOutputFromProps, headerText, subTitle } = props;
  const eventOutputSource = eventOutput ?? eventOutputFromProps ?? {};
  const { onBack, onClose } = eventOutputSource ?? {};
  const Refresh = () => <button type="button" className="sui-underline" onClick={refreshFn}>Refresh</button>;
  const handleOnClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  const handleOnBack = () => {
    setOpen(false);
    if (onBack) onBack();
  };
  const Content = () => (
    <>
      {subTitle && <Typography variant="body-base" weight="bold">{subTitle}</Typography>}
      {subTitle && <div className="sui-h-6" />}
      <Alert status="error">{CONSTANTS.ERROR.MSG}<br /><Refresh /></Alert>
      <div className="sui-h-20" />
    </>
  );
  return (
    <>
      <DrawerHeader
        title={headerText ?? 'Error Occurred'}
        onBack={onBack ? handleOnBack : null}
        onClose={handleOnClose}
      />
      <DrawerBody>
        <Content />
      </DrawerBody>
    </>
    // // TODO: Enhance to use PacDrawer with passed in props if some of the data exists
    // <PacDrawer
    //   headerText={header}
    //   onBack={onBack ? handleOnBack : null}
    //   onClose={handleOnClose}
    //   icon={icon}
    //   title={title}
    //   Content={Content}
    // />
  );
};

export const useCheckAndFetchPerks = () => {
  const { infoPerks } = React.useContext(PacDrawerContext);
  const checkAndFetchPerks = React.useCallback(() => {
    if (!infoPerks.infoPerksData) infoPerks.getPerksResponse();
  }, []);
  return { checkAndFetchPerks };
};

const determineLoadingAndErrors = (params) => {
  const { infoPerks, perkActivationRequest, programId, fetchedActivatePerks } = params;
  const { isLoadingActivatePerks, activatePerksError, activatePerksData } = fetchedActivatePerks ?? {};

  return {
    isLoading: (!programId && infoPerks?.isLoadingInfoPerks) || (programId && isLoadingActivatePerks),
    hasInfoPerksError:
      !infoPerks?.isLoadingInfoPerks && (infoPerks?.infoPerksError || !perkActivationRequest.programId),
    hasActivatePerksError: !isLoadingActivatePerks && (activatePerksError || !activatePerksData?.paymentId),
  };
};

export const SelectionConfirmationDrawer = (props) => {
  const { infoPerks, eventOutput } = React.useContext(PacDrawerContext) ?? {};
  const { checkAndFetchPerks } = useCheckAndFetchPerks();
  const { payloadData, eventOutput: eventOutputFromProps } = props;
  const eventOutputSource = eventOutput ?? eventOutputFromProps ?? {};
  const { programId: programIdFromEvent } = eventOutputSource ?? {};
  const { optionObj, tierObj } = payloadData ?? {};
  const tierPerks = tierObj?.tierPerks?.[0];
  const programId = programIdFromEvent ?? infoPerks?.infoPerksData?.program?.programId;

  React.useEffect(() => {
    if (!programId) checkAndFetchPerks();
  }, []);

  const perkActivationRequest = React.useMemo(() => ({
    perkId: tierPerks?.perkId,
    tierRewardId: optionObj?.tierRewardId,
    programId,
    tierId: tierObj?.tierId,
  }), [programId]);

  const fetchedActivatePerks = useFetchActivatePerks({ perkActivationRequest });
  const { activatePerksData, getActivateResponse, activatePerksError } = fetchedActivatePerks;

  React.useEffect(() => {
    if (programIdFromEvent || (programId && !infoPerks?.isLoadingInfoPerks)) {
      getActivateResponse();
    }
  }, [programId, infoPerks?.isLoadingInfoPerks]);

  React.useEffect(() => {
    if (!activatePerksError && activatePerksData) {
      if (windowExists()) {
        window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
          'loyalty-benefits.perk-selection-drawer__activate-perk',
          {},
        );
        incrementPerkCounter();
        qualtricsRefresh();
      }
    }
  }, [activatePerksData]);

  const {
    isLoading, hasInfoPerksError, hasActivatePerksError
  } = determineLoadingAndErrors({ infoPerks, perkActivationRequest, programId, fetchedActivatePerks });

  if (isLoading) return <LoadingPacDrawer headerText="New Perk Unlocked" />;
  if (hasInfoPerksError) {
    return (
      <ErrorSelectionDrawer
        eventOutput={eventOutputSource}
        refreshFn={infoPerks?.refreshInfoPerks}
        headerText={CONSTANTS.HEADER.UNLOCKED}
        subTitle={CONSTANTS.CONTENT_HEADER.UNLOCKED}
      />
    );
  }
  if (hasActivatePerksError) {
    return (
      <ErrorSelectionDrawer
        eventOutput={eventOutputSource}
        refreshFn={getActivateResponse}
        headerText={CONSTANTS.HEADER.UNLOCKED}
        subTitle={CONSTANTS.CONTENT_HEADER.UNLOCKED}
      />
    );
  }

  const activatedTier = {
    ...tierObj,
    tierPerks: [
      {
        ...tierObj?.tierPerks?.[0],
        ...optionObj,
        perkStatus: 'ACTIVE',
        paymentId: activatePerksData?.paymentId,
        activationTime: activatePerksData?.activationTime,
        expirationTime: activatePerksData?.expirationTime,
      },
    ],
  };

  // trigger events
  if (windowExists()) {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.selection-confirmation-drawer__close',
      {},
    );
  }

  return (
    <RewardBarcodeDrawer
      perkObj={activatedTier?.tierPerks?.[0]}
      eventOutput={eventOutputSource}
      isNewlyActivated
    />
  );
};
