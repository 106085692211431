import React from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import './NotificationSection.scss';
import { getAccrualData, isObjectEmpty } from '../../../common/utils/CardUtils';
import {
  StatusUnavailable,
  NotificationSkeleton,
} from '../../../common/StatusUnavailableNotifications/StatusUnavailable';
import {
  ProgressPerk,
  PerkWithStaticIcon,
  ProCreditCard,
  PreferredUnlocked,
  AvailableOffers,
  PaintMemberProducts,
  ExpiringOffers,
} from './Notifications';
import { analytics } from '../../../core/Constants';
import { EliteSupportLine } from './Notifications/OfferNotifications';

export const showingTileNotifications = ({ isAdmin, userData, availablePerksData }) => {
  const { perkSummary } = userData || {};
  const { activePerksCount } = perkSummary || {};
  const accrualData = getAccrualData(perkSummary);
  const proXtraCCProgram = perkSummary?.pxccUserCategory === 'Enrolled';
  const showRenderPerks = isAdmin
    && !isObjectEmpty(accrualData)
    // enrolledInPerksProgram &&
    && (perkSummary?.unclaimedPerksCount > 0
      || (!accrualData?.finalTierPerkAchieved && proXtraCCProgram)
      || accrualData?.finalTierPerkAchieved
      || availablePerksData !== undefined
      || activePerksCount);

  return {
    RenderPerksProgress: showRenderPerks,
    EnrollmentPrograms: true,
  };
};

const RenderPerksProgress = ({ propPack }) => {
  const {
    accrualData, perkSummary, userProfile, proXtraCCProgram,
    availablePerksData, isLoadingPerks, availablePerksError, refreshAvailablePerks,
  } = propPack;
  const finalTierPerkAchieved = accrualData.finalTierPerkAchieved;
  const { unclaimedPerksCount } = perkSummary || {};
  const data = availablePerksData;
  const error = !availablePerksData || isObjectEmpty(availablePerksData) || availablePerksError;
  const analyticsMessage = analytics.ANALYTICS_MESSAGE;
  const analyticsLinks = analytics.ANALYTICS_LINKS;
  const isPacDrawersEnabled = ['1', 1].includes(useConfigService('ENABLE_LOYALTY_PAC_DRAWERS'));
  const perkCount = data?.availablePerks + data?.expiringPerks;
  if (isLoadingPerks) {
    return <NotificationSkeleton />;
  }

  // State 1, final tier achieved, no unclaimed perks, no available perks
  if (finalTierPerkAchieved && perkCount === 0 && unclaimedPerksCount < 1) {
    return (
      <PerkWithStaticIcon
        sublabel={`${userProfile.name.firstName} ${userProfile.name.lastName}`}
        analyticsMessage={analyticsMessage.WELL_DONE}
        analyticsLinks={analyticsLinks.PERKSLINK}
      />
    );
  }

  // State 2, expiring perks
  if (data?.expiringPerks > 0) {
    return (
      <PerkWithStaticIcon
        label={`${data?.expiringPerks} Perks Expiring Soon`}
        analyticsMessage={analyticsMessage.Perks_Expiring_Soon}
        refLink="/myaccount/proXtraRewards?drawerList=perk"
        analyticsLinks={analyticsLinks.VIEW_PERKS}
      />
    );
  }

  // State 3, unclaimed perks
  if (unclaimedPerksCount > 0) {
    const refLink = isPacDrawersEnabled ? '/myaccount/proXtraRewards?drawerList=perk' : '/myaccount/proXtraPerks';
    return (
      <PerkWithStaticIcon
        label="New Perk Unlocked"
        imgUrl="badge-unlocked.svg"
        linkText="Choose Perk"
        analyticsMessage={analyticsMessage.New_Perk_Unlocked}
        analyticsLinks={analyticsLinks.CHOOSE_PERK}
        refLink={refLink}
      />
    );
  }

  // State 4, available perks count
  if (data?.availablePerks > 0) {
    return (
      <PerkWithStaticIcon
        label={`${data?.availablePerks} Perks Available`}
        analyticsMessage={analyticsMessage.Perks_Available}
        analyticsLinks={analyticsLinks.VIEW_PERKS}
        refLink="/myaccount/proXtraRewards?drawerList=perk"
      />
    );
  }

  if (!finalTierPerkAchieved) {
    if (proXtraCCProgram) {
      // State 5, not final tier, enrolled in PXCC
      return <ProCreditCard perkSummary={perkSummary} />;
    }
    // State 6, not final tier, not enrolled in PXCC
    return <ProgressPerk perkSummary={perkSummary} />;
  }

  return (
    <StatusUnavailable
      error={error}
      isLoadingPerks={isLoadingPerks}
      refreshFn={refreshAvailablePerks}
    />
  );
};

const EnrollmentPrograms = ({ propPack }) => {
  const {
    availablePerksData, isLoadingPerks, currentTierName, benefitTags,
    phoneTagName, customerInfo, currentPaintLevel, refreshAvailableOffers,
  } = propPack;
  const data = availablePerksData;

  if (isLoadingPerks) {
    return <NotificationSkeleton />;
  }

  if (data) {
    // State 1, expiring offers count
    if (data?.expiringOffers > 0) {
      return <ExpiringOffers expiringOffersCount={data.expiringOffers} />;
    }
    // State 3, available offers count
    if (data?.availableOffers > 0) {
      return <AvailableOffers availableOffersCount={data.availableOffers} />;
    }
  }
  // State 4, VIP or Elite, elite Support Line
  const isAtLeastElite = currentTierName.toUpperCase() === 'ELITE' || currentTierName.toUpperCase() === 'VIP';
  if (isAtLeastElite) {
    const phoneTag = benefitTags?.find((tag) => {
      return tag.tagName === phoneTagName;
    });
    return <EliteSupportLine phoneTag={phoneTag} />;
  }

  // State 5 Member, enrolled in preferred pricing
  if (customerInfo?.enrolledInPreferredPricing) {
    return <PreferredUnlocked />;
  }

  // State 6, Member, not enrolled in preferred pricing
  if (!customerInfo?.enrolledInPreferredPricing) {
    return <PaintMemberProducts currentPaintLevel={currentPaintLevel} />;
  }

  // fall back state, status unavailable
  return (
    <StatusUnavailable
      isLoadingPerks={isLoadingPerks}
      refreshFn={refreshAvailableOffers}
      isPerks={false}
    />
  );
};

export const prioritizedListNotifications = ({
  showingTile,
  userData,
  availablePerksData,
  isLoadingPerks,
  refreshAvailablePerks,
  refreshAvailableOffers,
  availablePerksError,
}) => {
  const { customerInfo, perkSummary, userProfile, proTiers } = userData || {};
  const proXtraCCProgram = perkSummary?.pxccUserCategory === 'Enrolled';
  const accrualData = getAccrualData(perkSummary);
  const currentPaintLevel = customerInfo?.paintMembershipLevel
    ? customerInfo?.paintMembershipLevel
    : 'Member';
  const phoneTagName = 'eliteCallCenter';
  const currentTierName = proTiers?.currentTierName;
  const benefitTags = proTiers?.benefitTags;

  const propsPerkProgress = {
    accrualData,
    perkSummary,
    userProfile,
    proXtraCCProgram,
    availablePerksData,
    isLoadingPerks,
    availablePerksError,
    refreshAvailablePerks,
  };
  const propsEnrollmentPrograms = {
    availablePerksData,
    isLoadingPerks,
    currentTierName,
    benefitTags,
    phoneTagName,
    customerInfo,
    currentPaintLevel,
    refreshAvailableOffers,
  };

  const prioritizedList = [
    customerInfo?.enrolledInPerksProgram && showingTile.RenderPerksProgress && (
      <RenderPerksProgress propPack={propsPerkProgress} />
    ),
    // showingTile.AvailableOffers && <AvailableOffers />,
    showingTile.EnrollmentPrograms && <EnrollmentPrograms propPack={propsEnrollmentPrograms} />,
  ];

  return prioritizedList;
};

const NotificationSection = ({ tiles }) => {
  const DisplayTiles = () => tiles;

  return <DisplayTiles />;
};

export const exportsForTesting = {
  ProgressPerk,
  PerkWithStaticIcon,
  PreferredUnlocked,
  ProCreditCard,
  PaintMemberProducts,
  StatusUnavailable,
  AvailableOffers,
};

export default NotificationSection;
