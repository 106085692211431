import React, { useContext } from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import { WelcomeCardContext } from '../../../contexts/WelcomeCardContext';
import { getUserRole, getHostCURL } from '../../../common/utils/CardUtils';
import { WelcomeCardHeader } from '../WelcomeCardHeader/WelcomeCardHeader';
import { NotificationSection } from '../NotificationSection';
import { WelcomeCardErrorPage } from '../WelcomeCardErrorPage/WelcomeCardErrorPage';
import { PromotionSection } from '../PromotionSection';
import { analytics } from '../../../core/Constants';
import { analyticsStash } from '../../../common/utils/AnalyticsUtils';

const exportsForTesting = {};

const TabletWelcomeCard = (props) => {
  const { isLoading, tiles, userDataError } = useContext(WelcomeCardContext);
  const { handleOnClick } = props;
  const isAdmin = getUserRole() === 'ADMIN';
  const learnMoreLink = getHostCURL('/myaccount/proXtraRewards');
  const linkText = 'View All Rewards & Benefits';
  const Card = () => {
    return (
      <div className="loyalty-welcome">
        <div className="header-main">
          <div>
            <WelcomeCardHeader />
          </div>
          <div className="header-label">
            <Button
              variant="text"
              href={learnMoreLink}
              fullWidth
              onClick={() => analyticsStash(
                'welcomeCard', analytics.ANALYTICS_MESSAGE.BOTTOM_LINK, analytics.ANALYTICS_LINKS.VIEW_BENEFITS)}
            >
              {linkText}
            </Button>
          </div>
        </div>
        <div className="notifcation-main">
          {isAdmin ? (
            <NotificationSection tiles={tiles.TABLET} />
          ) : (
            <PromotionSection tiles={tiles.TABLET} />
          )}
        </div>
      </div>
    );
  };

  const RenderTabletWelcomeCard = () => {
    return (
      <div data-component="LoyaltyBenefits">
        {!isLoading && !userDataError ? (
          <Card />
        ) : (
          <WelcomeCardErrorPage handleOnClick={handleOnClick} cardLoaded={!isLoading} />
        )}
      </div>
    );
  };

  exportsForTesting.handleOnClick = handleOnClick;
  exportsForTesting.Card = Card;
  exportsForTesting.RenderTabletWelcomeCard = RenderTabletWelcomeCard;

  return <RenderTabletWelcomeCard />;
};

export { TabletWelcomeCard, exportsForTesting };
