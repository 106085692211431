import React from 'react';
import { PxccMultiplierIcon } from './IconUtils';
import { floorNumberWithCommas } from './CurrencyUtils';
import { HEADER_TEXT, SUBTITLE_TEXT } from '../../core/DrawerConstants';
import { windowExists } from './AccountUtils';

export const getRewardAttributes = (currentRewardDetails) => {
  const {
    activationType,
    giftCard,
    availability,
    offerType,
    perkStatus,
  } = currentRewardDetails ?? {};

  return {
    // reward types
    isPerk: offerType === 'PERK',
    isOffer: offerType === 'OFFER',
    // availability types
    isInStore: availability === 'IN_STORE',
    isOnline: availability === 'ONLINE',
    isOmni: availability === 'OMNI_CHANNEL',
    // hybrid availability types
    isInStoreOrOmni: availability === 'IN_STORE' || availability === 'OMNI_CHANNEL',
    isOnlineOrOmni: availability === 'ONLINE' || availability === 'OMNI_CHANNEL',
    // activation types
    isPricingEngine: activationType === 'PRICING_ENGINE',
    isCms: activationType === 'CMS',
    isPayments: activationType === 'PAYMENTS',
    // misc.
    isGiftCard: giftCard,
    // statuses
    isLocked: !currentRewardDetails?.perkStatus,
    isUnclaimed: perkStatus === 'UNCLAIMED',
    isActive: perkStatus === 'ACTIVE',
    isRedeemed: perkStatus === 'REDEEMED',
    isInactive: perkStatus === 'INACTIVE',
    // reward types
    isToolRentalPerk: activationType === 'PRICING_ENGINE' && !giftCard && availability === 'IN_STORE',
    isOffersOnline: activationType === 'PRICING_ENGINE' && !giftCard && availability === 'ONLINE',
    isCmsOffer: activationType === 'CMS' && !giftCard && availability === 'IN_STORE',
    isCmsPerkB: activationType === 'CMS' && offerType === 'PERK',
    isPxdReward: activationType === 'PAYMENTS' && giftCard && availability === 'OMNI_CHANNEL',
  };
};

export const getDaysUntil = (dateString) => {
  if (!dateString) return 0;
  const currentDate = new Date();
  const date = new Date(dateString);
  return Math.ceil(
    (date.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
  );
};

export const getHostURL = (pathname) => {
  let hostName = 'www.homedepot.com';
  if (windowExists()) {
    hostName = window?.THD_GLOBAL?.secureHostName || hostName;
  }
  return `https://${hostName}/${pathname}`;
};

export const getCookie = (name) => {
  if (typeof document === 'undefined') return ('');
  const cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name + '=') === 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return '';
};

export const getCookieProperty = (cookieName, propertyName) => {
  const cookieValue = getCookie(cookieName);
  if (cookieValue) {
    try {
      const decodedUrl = decodeURIComponent(cookieValue);

      const parts = decodedUrl.split(':;');
      const thdPersistCookie = {};

      parts.forEach((part) => {
        const [key, value] = part.split('=');
        try {
          thdPersistCookie[key] = JSON.parse(value);
        } catch (err) {
          thdPersistCookie[key] = value;
        }
      });
      return parseInt(thdPersistCookie[propertyName]?.I1, 10);
    } catch (err) {
      console.error('Error parsing cookie URL', err);
    }
  }
  return null;
};

export const formatRewardText = (reward) => {
  if (!reward) {
    return {
      header: null,
      title: null,
      subtitle: null,
    };
  }

  const { isPerk, isCms, isPricingEngine, isOnline } = getRewardAttributes(reward);
  const displayAmount = isCms || (isOnline && isPricingEngine) ? '' : `$${reward?.amount} `;

  return {
    header: isPerk ? HEADER_TEXT.PERK_DETAILS : HEADER_TEXT.OFFER,
    title: displayAmount + reward?.perkTitle,
    subtitle: isPerk ? SUBTITLE_TEXT.ACTIVE_PERK : SUBTITLE_TEXT.OFFER,
  };
};

export const formatSpendUntilThisPerkSubtitle = (props) => {
  const { tierObj, accrualData, pxccUserCategory, activePxccFactor } = props;
  const isPxccEnrolled = pxccUserCategory === 'Enrolled';
  let untilSpend = 0;
  if (accrualData && pxccUserCategory) {
    const accrualYear = accrualData?.[0] ?? {};
    untilSpend = isPxccEnrolled ? accrualYear.spendForNextPerkWithPxcc : accrualYear.spendForNextPerk;
  } else if (tierObj) {
    untilSpend = tierObj?.earned ? 0 : tierObj?.spendForNextTier;
  }
  const formattedUntilSpend = floorNumberWithCommas(untilSpend ?? 0, 0);
  return (
    <>
      <span className="sui-mr-1">${formattedUntilSpend} until this Perk</span>
      {isPxccEnrolled && <>with <PxccMultiplierIcon activePxccFactor={activePxccFactor} /></>}
    </>
  );
};

export const editLiveChatZIndex = () => {
  if (!windowExists()) return;
  const changeZIndex = (it) => {
    if (it?.parentElement?.classList) {
      it.parentElement.classList.add('rh-z-index-floor');
    }
  };
  document.querySelectorAll('img[alt="Live Chat"]')
    .forEach((it) => changeZIndex(it));
  document.querySelectorAll('img[alt="Feedback Link"]')
    .forEach((it) => changeZIndex(it));
};

export const incrementPerkCounter = () => {
  const currentCount = Number(sessionStorage.getItem('perkCounter'));
  sessionStorage.setItem('perkCounter', (currentCount + 1).toString());
};

export const qualtricsRefresh = () => {
  if (window.QSI && window.QSI.API) {
    window.QSI.API.unload();
    window.QSI.API.load();
    window.QSI.API.run();
  } else {
    console.error('QSI API is not available');
  }
};

export const capitalizeFirstLetter = (string) => {
  const lowerCasedString = string.toLowerCase();
  return lowerCasedString.charAt(0).toUpperCase() + lowerCasedString.slice(1);
};
