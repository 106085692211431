import React, {
  useEffect,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  string, number, bool, shape, array, arrayOf
} from 'prop-types';
import {
  ExperienceContext,
  useStore,
  useConfigService
} from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { Carousel } from '@one-thd/sui-carousel';
import {
  Card, CardTitle, Typography, Skeleton, SkeletonLine, SkeletonBlock
} from '@one-thd/sui-atomic-components';
import { ProductPodUtils } from '@thd-olt-component-react/product-pod';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import {
  useDataModel,
  extend,
  QueryProvider,
  QueryContext,
} from '@thd-nucleus/data-sources';
import classNames from 'classnames';
import { RetailMediaCarouselPod } from './RetailMediaCarouselPod';
import { getSchema, isExperienceDataResolved, showCarouselPlaceholder, nValueIsValid } from '../../utils';
import './RetailMediaCarousel.scss';
import { useNodeRef, useRetailMediaCarouselTelemetry } from '../../hooks';
import { dataModel } from './RetailMediaCarousel.dataModel';

const MAX_SLIDES_PER = 6;
const DEFAULT_BREAKPOINTS = {
  sm: {
    slidesPerView: 3,
    slidesPerGroup: 3
  },
  md: {
    slidesPerView: 4,
    slidesPerGroup: 4
  },
  lg: {
    slidesPerView: 5,
    slidesPerGroup: 5
  },
  xl: {
    slidesPerView: 6,
    slidesPerGroup: 6
  },
  '2xl': {
    slidesPerView: 6,
    slidesPerGroup: 6
  }
};

const propTypes = {
  breadCrumbs: arrayOf(shape({
    browseUrl: string,
    label: string,
  })),
  browserId: string,
  userId: string,
  categorySourceId: string,
  dynamic: shape({
    pageType: string,
  }),
  pageContext: shape({
    label: string,
    data: shape({
      searchModel: shape({
        appliedDimensions: array,
      }),
      itemId: string,
      itemIds: arrayOf(string),
    }),
    keyword: string,
    isSearch: bool,
    isCategory: bool,
    isBrowse: bool,
    isPip: bool,
    isDiscoveryZone: bool,
    schema: string,
    slugId: string
  }),
  /**
   * Number of slides per view (slides visible at the same time on slider's container)
   * based on breakpoint.
   *
   * This property has higher precedence over the 'slidesPerView' property.
   *
   * If provided partially, the missing values will be set to the 'slidesPerView'
   * property if defined, or from the default properties otherwise.
   *
   * For any value greater than MAX_SLIDES_PER, the MAX_SLIDES_PER value will be set instead.
   */
  breakpointsSlidesPer: shape({
    sm: number,
    md: number,
    lg: number,
    xl: number,
    '2xl': number
  }),
  /**
   * If provided, this value will setup both 'slidesPerView' and 'slidesPerGroup'
   * breakpoints properties, for all the viewport sizes.
   *
   * The 'breakpointsSlidesPer' property has higher precedence over this property.
   *
   * If this value is greater than MAX_SLIDES_PER, the MAX_SLIDES_PER value will be set instead.
   */
  slidesPer: number,
  nValue: string,
  noATCFulfillment: bool,
  utilizeRvData: bool,
  mockEnabledFeatureSwitch: bool,
  showSponsoredCarousel: bool,
  hideCarouselArrows: bool,
  dataSource: string,
  enableForChapters: bool,
  requestedAdCount: number,
  schema: string,
  plaLocation: string,
  useSvocIdAsCustomerId: bool,
  tntCarouselType: string,
  border: bool,
};

const defaultProps = {
  breadCrumbs: null,
  browserId: '',
  userId: '',
  categorySourceId: null,
  pageContext: {},
  dynamic: {},
  breakpointsSlidesPer: {},
  slidesPer: null,
  noATCFulfillment: null,
  nValue: null,
  utilizeRvData: false,
  mockEnabledFeatureSwitch: false,
  showSponsoredCarousel: false,
  hideCarouselArrows: false,
  dataSource: 'pla',
  requestedAdCount: 25,
  enableForChapters: false,
  schema: undefined,
  plaLocation: '',
  useSvocIdAsCustomerId: false,
  tntCarouselType: null,
  border: false,
};

const RetailMediaCarouselComponent = (props) => {
  const {
    breadCrumbs,
    browserId,
    userId,
    categorySourceId,
    pageContext,
    breakpointsSlidesPer,
    slidesPer,
    noATCFulfillment,
    nValue,
    mockEnabledFeatureSwitch,
    showSponsoredCarousel,
    hideCarouselArrows,
    dataSource,
    enableForChapters,
    utilizeRvData,
    requestedAdCount,
    dynamic,
    useSvocIdAsCustomerId,
    tntCarouselType,
    border,
  } = props;

  const experienceContext = useContext(ExperienceContext);
  const isMobile = experienceContext?.channel === 'mobile';
  const thdCustomer = useThdCustomer();
  const renderNewCarouselPip = useConfigService('fs-prop:pip-master__sponsored-carousel-nucleus--renderNewCarousel');
  const carouselTypeConfig = useConfigService('fs-prop:tnt-carouselType');
  const renderNewCarouselBrowseAndSearch = useConfigService('fs-prop:browse-and-search-master__sponsored-carousel-nucleus--renderNewCarousel');
  const { storeId, isLocalized, storeZip } = useStore({ varnish: true, online: true });
  const targetingSource = 'plaModel';
  const { defaultVariables } = useContext(QueryContext);

  // node
  const [carouselNodeRef, carouselNode] = useNodeRef();

  // --
  /**
   * Sets number of slides to show based on breakpoints.
   * The order of precedence, from higher to lower is:
   *   breakpointsSlidesPer
   *   slidesPer
   *   DEFAULT_BREAKPOINTS
   */
  const breakpoints = useMemo(() => {
    return {
      sm: {
        slidesPerView: Math.min(breakpointsSlidesPer?.sm ?? slidesPer ?? DEFAULT_BREAKPOINTS.sm.slidesPerView, MAX_SLIDES_PER),
        slidesPerGroup: Math.min(breakpointsSlidesPer?.sm ?? slidesPer ?? DEFAULT_BREAKPOINTS.sm.slidesPerGroup, MAX_SLIDES_PER)
      },
      md: {
        slidesPerView: Math.min(breakpointsSlidesPer?.md ?? slidesPer ?? DEFAULT_BREAKPOINTS.md.slidesPerView, MAX_SLIDES_PER),
        slidesPerGroup: Math.min(breakpointsSlidesPer?.md ?? slidesPer ?? DEFAULT_BREAKPOINTS.md.slidesPerGroup, MAX_SLIDES_PER)
      },
      lg: {
        slidesPerView: Math.min(breakpointsSlidesPer?.lg ?? slidesPer ?? DEFAULT_BREAKPOINTS.lg.slidesPerView, MAX_SLIDES_PER),
        slidesPerGroup: Math.min(breakpointsSlidesPer?.lg ?? slidesPer ?? DEFAULT_BREAKPOINTS.lg.slidesPerGroup, MAX_SLIDES_PER)
      },
      xl: {
        slidesPerView: Math.min(breakpointsSlidesPer?.xl ?? slidesPer ?? DEFAULT_BREAKPOINTS.xl.slidesPerView, MAX_SLIDES_PER),
        slidesPerGroup: Math.min(breakpointsSlidesPer?.xl ?? slidesPer ?? DEFAULT_BREAKPOINTS.xl.slidesPerGroup, MAX_SLIDES_PER)
      },
      '2xl': {
        slidesPerView: Math.min(breakpointsSlidesPer?.['2xl'] ?? slidesPer ?? DEFAULT_BREAKPOINTS['2xl'].slidesPerView, MAX_SLIDES_PER),
        slidesPerGroup: Math.min(breakpointsSlidesPer?.['2xl'] ?? slidesPer ?? DEFAULT_BREAKPOINTS['2xl'].slidesPerGroup, MAX_SLIDES_PER)
      }
    };
  }, [
    slidesPer,
    breakpointsSlidesPer?.sm,
    breakpointsSlidesPer?.md,
    breakpointsSlidesPer?.lg,
    breakpointsSlidesPer?.xl,
    breakpointsSlidesPer?.['2xl']
  ]);

  /**
   * MCMID
   */
  const [mcmid, setMcmid] = useState(experienceContext?.cookie?.adobeCookie?.MCMID);

  // updater
  useEffect(() => {
    setMcmid((cur) => {
      // if the new incoming value is not set, then keep the current value.
      if (!experienceContext?.cookie?.adobeCookie?.MCMID) return cur;
      return experienceContext?.cookie?.adobeCookie?.MCMID;
    });
  }, [experienceContext?.cookie?.adobeCookie?.MCMID]);
  // --

  // --
  const validBrowserId = useMemo(() => {
    return browserId || thdCustomer?.mcvisID || mcmid || '';
  }, [browserId, mcmid, thdCustomer?.mcvisID]);

  // --
  const customerUserId = useMemo(() => {
    if (useSvocIdAsCustomerId) return userId || thdCustomer?.svocID || thdCustomer?.userID || thdCustomer?.guestUserID || null;
    return userId || thdCustomer?.userID || thdCustomer?.guestUserID || null;
  }, [userId, thdCustomer?.svocId, thdCustomer?.userID, thdCustomer?.guestUserID, useSvocIdAsCustomerId]);

  // --
  const customerSvocId = useMemo(() => {
    return thdCustomer?.svocID || null;
  }, [thdCustomer?.svocID]);

  // --
  const carouselType = useMemo(() => {
    return tntCarouselType || carouselTypeConfig;
  }, [tntCarouselType, carouselTypeConfig]);

  // --
  const schema = useMemo(() => {
    return getSchema({
      props: { schema: props?.schema },
      schema: pageContext?.schema,
      pageContext: {
        label: pageContext?.label,
        isCategory: pageContext?.isCategory,
        isSearch: pageContext?.isSearch,
        isBrowse: pageContext?.isBrowse,
      },
    }) || 'pip_sponsored';
  }, [
    props?.schema,
    pageContext?.schema,
    pageContext?.label,
    pageContext?.isCategory,
    pageContext?.isSearch,
    pageContext?.isBrowse,
  ]);

  const tntCarousel = carouselType === 'tnt' && schema !== 'discovery_Zones' && !pageContext?.isDiscoveryZone;

  // --
  const plaLocation = useMemo(() => {
    if (tntCarousel) return 'sponsoredTestAndTargetPlaCarousel';
    if (props?.plaLocation) return props.plaLocation;

    // --
    if (pageContext?.label === 'browse-search') {
      if (pageContext?.isCategory) return 'sponsoredCarouselCategoryPage';
      if (pageContext?.isSearch) return 'sponsoredCarouselSearchProductListingPage';
      if (pageContext?.isBrowse) return 'sponsoredCarouselBrowseProductListingPage';
    }

    // --
    if (pageContext?.isPip) return 'sponsoredCarouselProductInformationPage';

    // --
    if (pageContext?.isDiscoveryZone) return 'sponsoredCarouselBrowsePageDiscoveryZone';

    return null;
  }, [
    props?.plaLocation,
    pageContext?.label,
    pageContext?.isCategory,
    pageContext?.isSearch,
    pageContext?.isBrowse,
    pageContext?.isPip,
    pageContext?.isDiscoveryZone,
    tntCarousel
  ]);

  // --
  const renderNewCarousel = useMemo(() => {
    return (
      enableForChapters
      || renderNewCarouselPip
      || renderNewCarouselBrowseAndSearch
      || showSponsoredCarousel
      || mockEnabledFeatureSwitch
      || schema === 'browse_sponsored'
      || schema === 'cat_sponsored'
      || schema === 'cart_sponsored'
      || schema === 'event_browse_sponsored'
      || schema === 'hp_sponsored_auth'
      || schema === 'hp_sponsored'
      || schema === 'pip_sponsored'
      || schema === 'search_sponsored'
      || schema === 'storepage_sponsored'
      || schema === 'thankyoupage_sponsored'
      || schema === 'no_results_found_sponsored'
    );
  }, [
    enableForChapters,
    renderNewCarouselPip,
    renderNewCarouselBrowseAndSearch,
    showSponsoredCarousel,
    mockEnabledFeatureSwitch,
    schema,
  ]);

  //-
  // Extracting the 'breadCrumbs' property and assigning it to the 'breadCrumbs' variable.
  const breadCrumbValue = breadCrumbs?.map(({ __typename, ...rest }) => {
    // Filtering out null values from the rest of the object properties.
    const filteredRest = Object.fromEntries(
      Object.entries(rest).filter(([key, value]) => value !== null)
    );
    // Returning a new object with non-null properties after filtering.
    return { ...filteredRest };
  }) || null;

  // --
  const zipCode = useMemo(() => {
    return experienceContext?.deliveryZip || storeZip || '30339';
  }, [experienceContext?.deliveryZip, storeZip]);

  const channel = useMemo(() => {
    return experienceContext?.isConsumerApp ? 'mobileconsumer' : experienceContext?.channel;
  }, [experienceContext?.isConsumerApp, experienceContext?.channel]);

  // --
  const isResolvedContext = useMemo(() => {
    return isExperienceDataResolved({ isLocalized, experienceContext });
  }, [isLocalized, experienceContext]);

  // --
  const isNValueValid = useMemo(() => {
    if (plaLocation === 'sponsoredCarouselLandingPage') {
      return nValueIsValid(nValue);
    } return true;
  }, [nValue, plaLocation]);

  // --
  const skip = useMemo(() => {
    return (!isResolvedContext || !isNValueValid);
  }, [isResolvedContext, isNValueValid]);

  // --
  const itemIds = useMemo(() => {
    return pageContext?.data?.itemIds?.slice(0, 50) || null;
  }, [pageContext?.data?.itemIds]);

  /**
   * GraphQL query: plaModel
   */
  // ----
  const resp = useDataModel('plaModel', {
    skip,
    ssr: false,
    variables: {
      breadCrumbs: breadCrumbValue,
      categorySourceId,
      channel,
      customer: {
        mcVisId: validBrowserId,
        customerType: experienceContext?.customer?.type,
        userId: customerUserId,
        svocId: customerSvocId,
      },
      debug: false,
      itemId: pageContext?.data?.itemId,
      itemIds,
      keyword: pageContext?.keyword,
      nValue,
      requestedAdCount,
      schema,
      storeId,
      zipCode,
      plaLocation,
      ...(carouselType && { carouselType })
    },
  });
  const { data, loading, error } = resp;
  // ---

  /**
   * filteredProducts
   */
  // --- --- ---
  const filteredProducts = useMemo(() => {
    // check
    if (loading || error || !data) return null;
    if (!data.plaModel?.products?.length) return [];

    const products = data?.plaModel?.products;
    return products.filter((product) => ProductPodUtils.isATCEnabled(product));

  }, [data, error, loading]);
  // --- --- ---

  const carouselTitle = useMemo(() => {
    if (loading || error || !data) return null;
    const title = data?.plaModel?.carouselMetadata?.title || 'Sponsored Products';
    return title;
  }, [data, error, loading]);

  const ImpressionHeadline = useMemo(() => {
    const ImpressionHeadlineMap = {
      'Top Picks for You': 'SponsoredTopPicksForYou',
      'More to Explore': 'SponsoredMoreToExplore',
      'Keep Browsing': 'SponsoredKeepBrowsing'
    };
    // Adding default value equal to the previous static one in case title is not found into the map
    return ImpressionHeadlineMap[carouselTitle] || 'SponsoredProducts';
  }, [carouselTitle]);

  // -----------------
  // Telemetry
  // -----------------
  const { triggerSlideChange } = useRetailMediaCarouselTelemetry({
    carouselNode,
    data,
    loading,
    error,
    filteredProducts,
    requestedAdCount,
    renderNewCarousel,
  });

  // -----------------
  // Handlers
  // -----------------
  /**
   * Handles 'slideChange' from Swiper.
   */
  const onSlideChange = useCallback((swiper) => {
    triggerSlideChange(swiper);
  }, [triggerSlideChange]);

  // -----------------
  // Placeholder
  // -----------------

  const LoadingPlaceholders = () => {
    let numOfPlaceholders = isMobile ? 2 : 6;
    let placeholderHeight = isMobile ? 48 : 56;
    let txtPlaceholderLines = 6;
    let placeholder = [];
    for (let i = 0; i < numOfPlaceholders; i += 1) {
      placeholder.push(
        <Skeleton
          orientation="vertical"
          key={i}
        >
          <SkeletonBlock aspect="square" height={placeholderHeight} />
          {txtPlaceholderLines > 0 && <SkeletonLine variant="multi" fullWidth numberOfLines={txtPlaceholderLines} />}
        </Skeleton>);
    }
    return placeholder;
  };

  if (showCarouselPlaceholder(renderNewCarousel, loading, error)) {
    const placeholderGridClass = classNames('sui-grid', { 'sui-grid-cols-2': isMobile,
      'sui-grid-cols-6': !isMobile });
    return (
      <div className="dynamic-recs sui-w-full sui-p-4" data-component="SponsoredCarouselPlaceholder" data-testid="CarouselPlaceholder">
        <>
          <div className="sui-mt-4 sui-mb-2">
            <div className="sui-mb-3">
              <Typography variant="h2">Loading Recommendations</Typography>
            </div>
          </div>
        </>
        <div className={placeholderGridClass}>
          {LoadingPlaceholders()}
        </div>
      </div>
    );
  }

  // -----------------
  // Header
  // -----------------

  const HeaderComponent = () => {
    return (
      <div
        className="sponsored-nucleus-carousel__title"
        data-testid="sponsored-carousel-title"
      >
        <CardTitle
          header={(
            <Typography variant="h2">
              {pageContext?.isDiscoveryZone ? 'More To Explore' : carouselTitle}
            </Typography>
          )}
        />
        {(carouselTitle !== 'Sponsored Products' || pageContext?.isDiscoveryZone)
        && (
          <Typography color="subtle" className="sponsored-nucleus-carousel-sponsored-tag" style={{ marginTop: '3px' }}>Sponsored
          </Typography>
        )}
      </div>
    );
  };

  // -----------------
  // Carousel
  // -----------------
  return (
    <>
      {(renderNewCarousel && filteredProducts?.length) ? (
        <div
          ref={carouselNodeRef}
          className="sui-w-full"
          id="sponsored-carousel-nucleus"
          data-component="RetailMediaCarousel"
        >
          <div
            id={schema}
            className="sponsored-nucleus-carousel"
            data-testid="sponsored-pla-carousel"
          >
            <meta data-prop="name" content={schema} />
            <Card
              className="sui-h-full sui-leading-none"
              PaperProps={{
                variant: border ? 'outlined' : 'shadow'
              }}
            >
              <HeaderComponent />
              <QueryProvider
                cacheKey="sponsored-product-pod"
                dataSource={dataSource}
                defaultVariables={{
                  storeId,
                  zipCode,
                  ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
                    isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
                  })
                }}
              >
                <Carousel
                  breakpoints={breakpoints}
                  hideCarouselArrows={hideCarouselArrows}
                  disableMargin
                  {...(isMobile ? ({ slidesPerGroup: 2 }) : {})}
                  SwiperProps={{
                    onSlideChange,
                  }}
                >
                  {filteredProducts.map((product, idx) => {
                    const { canonicalUrl } = product?.identifiers || {};
                    return (
                      <RetailMediaCarouselPod
                        key={product.itemId}
                        product={product}
                        itemId={product.itemId}
                        impressionHeadline={ImpressionHeadline}
                        storeId={storeId}
                        position={idx + 1}
                        schema={schema}
                        noATCFulfillment={noATCFulfillment}
                        targetingSource={targetingSource}
                        showAtc={!pageContext?.isDiscoveryZone}
                        url={canonicalUrl}
                      />
                    );
                  })}
                </Carousel>
              </QueryProvider>
            </Card>
          </div>
        </div>
      ) : null}
    </>
  );
};

RetailMediaCarouselComponent.propTypes = propTypes;
RetailMediaCarouselComponent.defaultProps = defaultProps;
RetailMediaCarouselComponent.dataModel = dataModel;

/* eslint react/jsx-props-no-spreading: 0 */
const QueryProvidedRetailMediaCarousel = (props) => {
  const { isClientResolved, defaultVariables } = useContext(QueryContext);

  const skipFn = ({ skip, queryName }) => {
    if (queryName === 'plaModel') {
      const isBrowseSearch = props?.pageContext?.label === 'browse-search';
      const isSearchModelResolved = isClientResolved({ queryName: 'searchModel' });

      // skip if 'searchModel' is not resolved yet, to avoid double call to 'plaModel' at load time.
      if (isBrowseSearch && !isSearchModelResolved) return true;
    }

    return skip;
  };

  return (
    <QueryProvider cacheKey="sponsored-nucleus-carousel" skip={skipFn} defaultVariables={{ ...(defaultVariables?.current || {}) }}>
      <RetailMediaCarouselComponent {...props} />
    </QueryProvider>
  );
};

QueryProvidedRetailMediaCarousel.propTypes = propTypes;
QueryProvidedRetailMediaCarousel.defaultProps = defaultProps;
QueryProvidedRetailMediaCarousel.dataModel = extend({}, RetailMediaCarouselComponent);

const RetailMediaCarousel = withErrorBoundary(
  withDynamicComponent(
    withHydrator(
      {
        id: 'sponsored-carousel',
        preserveCtxVal: 'clientStore'
      },
      QueryProvidedRetailMediaCarousel
    )
  )
);
RetailMediaCarousel.displayName = 'RetailMediaCarousel';
export { RetailMediaCarousel, RetailMediaCarouselComponent };
