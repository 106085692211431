import React, { createContext } from 'react';
import { bool as boolType, object as objectType, func as funcType } from 'prop-types';
import { LoyaltyWelcomeCardModel } from '../common/models/UserInfoModels';
import { GatherMockData } from '../common/utils/ContextUtils';
import { getUserRole } from '../common/utils/CardUtils';
import { createTiles } from '../common/utils/TileUtils';
import { useFetchHomepage, useFetchAvailablePerks } from '../hooks';

export const WelcomeCardContext = createContext({});

WelcomeCardContext.propTypes = {
  isAdmin: boolType,
  isLoading: boolType,
  userDataError: boolType,
  userData: objectType,
  challengesData: objectType,
  tiles: objectType,
  refreshData: funcType,
};

export const WelcomeCardProvider = ({ children }) => {
  const isAdmin = getUserRole() === 'ADMIN';
  const { userData, refreshData, isLoading, userDataError } = useFetchHomepage();
  const {
    availablePerksData, refreshAvailablePerks,
    refreshAvailableOffers, isLoadingPerks, availablePerksError
  } = useFetchAvailablePerks();

  const contextValue = {
    isAdmin,
    isLoading,
    userDataError,
    userData,
    availablePerksData,
    isLoadingPerks,
    tiles: createTiles({
      isAdmin,
      userData,
      availablePerksData,
      isLoadingPerks,
      availablePerksError,
      refreshAvailablePerks,
      refreshAvailableOffers,
    }),
    refreshData,
  };

  return <WelcomeCardContext.Provider value={contextValue}>{children}</WelcomeCardContext.Provider>;
};

WelcomeCardProvider.displayName = 'WelcomeCard Provider';
WelcomeCardProvider.dataModel = LoyaltyWelcomeCardModel;

export const exportsForTesting = {
  GatherMockData,
};
