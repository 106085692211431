/**
 * Note:
 * This file should be synced with the corresponding layout in Contentful CMS.
 */
import {
  params,
  arrayOf,
  string,
  fragment,
  shape,
  bool,
  extend,
  customType,
  number,
} from '@thd-nucleus/data-sources';
import { Spotlight, SpotlightContainer } from '@thd-olt-component-react/spotlight';
import { ShopByCategory } from '@thd-olt-component-react/shop-by-category';
import { VisualNavigation, PromoVisualNavigation } from '@thd-olt-component-react/visual-navigation';
import { ComponentSelector, Slot } from '@thd-olt-component-react/component-selector';
import { MedioInline, PiccolaPencil } from '@thd-olt-component-react/internal-marketing-banner';
import { EventCountdownTimer } from '@thd-olt-component-react/event-countdown-timer';

let CapabilitiesTile = {};
CapabilitiesTile.dataModel = {
  component: params({
    id: string().isRequired(),
  }).shape({
    CapabilitiesTile: fragment().shape({
      link: string(),
      id: string(),
      __typename: string(),
      title: string(),
      description: string(),
      cta: string(),
      previewImage: shape({
        damContentSelector: shape({
          assetData: arrayOf(shape({
            selectedImageUrl: string()
          }))
        }),
        damDownloadedContent: shape({
          url: string()
        })
      }),
    }),
  }),
};

let HomeDecorCard = {};
HomeDecorCard.dataModel = {
  id: string(),
  __typename: string(),
  title: string(),
  link: string(),
  componentName: string(),
  cta: string(),
  showCTA: bool(),
  categoryComponents: arrayOf({
    id: string(),
    title: string(),
    visualNavigationList: arrayOf(shape({
      id: string(),
      title: string(),
      link: string(),
      // image: shape({
      //   url: string()
      // })
      previewImage: shape({
        damDownloadedContent: shape({
          url: string()
        })
      })
    }))
  }),
};

const HeroFlattenImage = {
  dataModel: {
    id: string(),
    mobilePreviewImage: shape({
      damContentSelector: shape({
        assetData: arrayOf(
          shape({
            selectedImageUrl: string(),
          }),
        ),
      }),
      damDownloadedContent: shape({
        url: string(),
      }),
      imageHotspot: shape({
        id: string(),
        componentName: string(),
        imageUrl: string()
      })
    }),

    previewImage: shape({
      damContentSelector: shape({
        assetData: arrayOf(
          shape({
            selectedImageUrl: string(),
          }),
        ),
      }),
      damDownloadedContent: shape({
        url: string(),
      }),
      imageHotspot: shape({
        id: string(),
        componentName: string(),
        imageUrl: string()
      })
    }),

    title: string(),
    link: string(),
    componentName: string(),
    proAnalyticsCampaign: string(),
    proAnalyticsComponent: string(),

    __typename: string(),
  },
};

Slot.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['Slot'])
      .isRequired(),
  }).shape({
    Slot: fragment().shape({
      id: string(),
      name: string(),
      slotNumber: number(),
      content: shape({
        ...Spotlight.dataModel.component,
        HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
        HomeDecorCard: fragment().shape(HomeDecorCard.dataModel),
      })
    })
  })
};

ComponentSelector.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['ComponentSelector'])
      .isRequired(),
  }).shape({
    ComponentSelector: fragment().shape({
      id: string(),
      targetingType: shape({
        AdobeTarget: fragment().shape({
          id: string(),
          mbox: string(),
        }),
        MultiArmBandit: fragment().shape({
          id: string(),
          campaignId: string(),
          channel: string(),
        }),
        Innervate: fragment().shape({
          id: string(),
          tagID: string(),
          adkey: string()
        })
      }),
      defaultComponent: arrayOf(
        shape({
          ...Spotlight.dataModel.component,
          ...Slot.dataModel.component,
          HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
          HomeDecorCard: fragment().shape(HomeDecorCard.dataModel),
        }),
      ),
      variations: arrayOf(
        shape({
          ...Spotlight.dataModel.component,
          ...Slot.dataModel.component,
          HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
          HomeDecorCard: fragment().shape(HomeDecorCard.dataModel),
        }),
      ),
    }),
    ...Spotlight.dataModel.component,
    ...Slot.dataModel.component,
    HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
    HomeDecorCard: fragment().shape(HomeDecorCard.dataModel),
  }),
};

let PopularCategories = {};
PopularCategories.dataModel = {
  id: string(),
  __typename: string(),
  customRenderId: string()
};

let Recommendations = {};
Recommendations.dataModel = {
  id: string(),
  __typename: string(),
  customRenderId: string(),
  theme: string()
  // schemaData: string()
};

let SponsoredBanner = {};
SponsoredBanner.dataModel = {
  id: string(),
  __typename: string(),
  customRenderId: string(),
  schema: string(),
  imgDesktop: string(),
  imgMobile: string(),
  clickthruUrl: string(),
  theme: string(),
  specialDefaultBanner: shape({
    imgDesktop: string(),
    imgMobile: string(),
    clickthruUrl: string(),
    useSpecialBanner: bool()
  })
};

let PersonalizedHomeServices = {};
PersonalizedHomeServices.dataModel = {
  id: string(),
  __typename: string(),
  customRenderId: string(),
};

let PromotionCard = {};
PromotionCard.dataModel = {
  id: string(),
  customRenderId: string(),
  __typename: string(),
};

let RecentOrders = {};
RecentOrders.dataModel = {
  id: string(),
  customRenderId: string(),
  __typename: string(),
};

let RecentPurchases = {};
RecentPurchases.dataModel = {
  id: string(),
  customRenderId: string(),
  __typename: string(),
};

let SignIn = {};
SignIn.dataModel = {
  id: string(),
  customRenderId: string(),
  __typename: string(),
};

let SpecialBuyOfTheDay = {};
SpecialBuyOfTheDay.dataModel = {
  id: string(),
  customRenderId: string(),
  __typename: string(),
  theme: string(),
};

let SpecialBuyOfTheWeek = {};
SpecialBuyOfTheWeek.dataModel = {
  id: string(),
  customRenderId: string(),
  __typename: string(),
};

let LoyaltyBenefits = {};
LoyaltyBenefits.dataModel = {
  id: string(),
  customRenderId: string(),
  __typename: string(),
};

let PrivateLabelCreditCard = {};
PrivateLabelCreditCard.dataModel = {
  id: string(),
  customRenderId: string(),
  __typename: string(),
};

let OnlineWorkshops = {};
OnlineWorkshops.dataModel = {
  id: string().isRequired(),
  customRenderId: string(),
  __typename: string(),
};

let UserList = {};
UserList.dataModel = {
  id: string().isRequired(),
  customRenderId: string(),
  __typename: string(),
};

let SectionDM = {};
SectionDM.dataModel = {
  id: string(),
  title: string(),
  customRenderId: string(),
  isCarousel: bool(),
  theme: string(),
  components: arrayOf(
    shape({
      ...Spotlight.dataModel.component,
      ...SpotlightContainer.dataModel.specializedContainer,
      ...ComponentSelector.dataModel.component,
      ...CapabilitiesTile.dataModel.component,
      ...VisualNavigation.dataModel.component,
      ...PromoVisualNavigation.dataModel.component,
      ...MedioInline.dataModel.component,
      ...PiccolaPencil.dataModel.component,
      HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
    }),
  ),
  __typename: string(),
};

export const authB2cDataModelServerSideCall = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
  }).shape({
    id: string(),
    content: shape({
      __typename: string(),
      PersonalizedHomePage: fragment().shape({
        id: string(),
        pageTheme: string(),
        internalMarketingBanner: shape({ ...MedioInline.dataModel.component, ...PiccolaPencil.dataModel.component }),
        // you need this here too because the query is shaped at a lower level
        // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
        heroCarousel: shape({
          id: string(),
          customRenderId: string(),
          isCarousel: bool(),
          isAutoplay: bool(),
          theme: string(),
          components: arrayOf(
            shape({
              ...ComponentSelector.dataModel.component,
              HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
            }),
          ),
        }),
        welcomeMessage: string(),
      }),
    }),
    seo: shape({
      title: string(),
      description: string(),
      facebookAdmins: string(),
      siteName: string(),
      type: string(),
      canonicalURL: string(),
      keywords: arrayOf(string()),
      no_index: bool(),
      no_follow: bool(),
    }),
  }),
});

export const authB2cDataModelClientSideCall = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
  }).shape({
    id: string(),
    content: shape({
      __typename: string(),
      PersonalizedHomePage: fragment().shape({
        id: string(),
        pageTheme: string(),
        componentOrder: shape({
          ...Spotlight.dataModel.component,
          ...EventCountdownTimer.dataModel.component,
          Recommendations: fragment().shape(Recommendations.dataModel),
          Section: fragment().shape(SectionDM.dataModel),
          SponsoredBanner: fragment().shape(SponsoredBanner.dataModel),
          SpecialBuyOfTheDay: fragment().shape(SpecialBuyOfTheDay.dataModel),
          SpecialBuyOfTheWeek: fragment().shape(SpecialBuyOfTheWeek.dataModel),
          HomeDecorCard: fragment().shape(HomeDecorCard.dataModel),
          RecentOrders: fragment().shape(RecentOrders.dataModel),
          PrivateLabelCreditCard: fragment().shape(
            PrivateLabelCreditCard.dataModel,
          ),
          OnlineWorkshops: fragment().shape(OnlineWorkshops.dataModel),
          ...ShopByCategory.dataModel.component,
          UserList: fragment().shape(UserList.dataModel),
          SignIn: fragment().shape(SignIn.dataModel),
          PersonalizedHomeServices: fragment().shape(
            PersonalizedHomeServices.dataModel,
          ),
        }),
      }),
    }),
    seo: shape({
      title: string(),
      description: string(),
      facebookAdmins: string(),
      siteName: string(),
      type: string(),
      canonicalURL: string(),
      keywords: arrayOf(string()),
      no_index: bool(),
      no_follow: bool(),
    }),
  }),
  PrivateLabelCreditCard,
  SpecialBuyOfTheDay,
  Spotlight,
  VisualNavigation,
  PromoVisualNavigation
});

export const guestB2cServerSideDataModel = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
  }).shape({
    id: string(),
    content: shape({
      __typename: string(),
      PersonalizedHomePage: fragment().shape({
        id: string(),
        pageTheme: string(),
        internalMarketingBanner: shape({ ...MedioInline.dataModel.component, ...PiccolaPencil.dataModel.component }),
        // you need this here too because the query is shaped at a lower level
        // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
        heroCarousel: shape({
          id: string(),
          customRenderId: string(),
          isCarousel: bool(),
          isAutoplay: bool(),
          theme: string(),
          components: arrayOf(
            shape({
              ...ComponentSelector.dataModel.component,
              HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
            }),
          ),
        }),
        componentOrder: shape({
          PersonalizedHomeServices: fragment().shape(
            PersonalizedHomeServices.dataModel,
          ),
          PopularCategories: fragment().shape(
            PopularCategories.dataModel
          ),
          PrivateLabelCreditCard: fragment().shape(
            PrivateLabelCreditCard.dataModel,
          ),
          Recommendations: fragment().shape(Recommendations.dataModel),
          SpecialBuyOfTheDay: fragment().shape(SpecialBuyOfTheDay.dataModel),
          SpecialBuyOfTheWeek: fragment().shape(SpecialBuyOfTheWeek.dataModel),
          ...EventCountdownTimer.dataModel.component,
          Section: fragment().shape(SectionDM.dataModel),
          ...ShopByCategory.dataModel.component,
          SponsoredBanner: fragment().shape(SponsoredBanner.dataModel),
          OnlineWorkshops: fragment().shape(OnlineWorkshops.dataModel),
          RecentOrders: fragment().shape(RecentOrders.dataModel),
          SignIn: fragment().shape(SignIn.dataModel),
          ...Spotlight.dataModel.component,
        }),
      }),
    }),
    seo: shape({
      title: string(),
      description: string(),
      facebookAdmins: string(),
      siteName: string(),
      type: string(),
      canonicalURL: string(),
      keywords: arrayOf(string()),
      no_index: bool(),
      no_follow: bool(),
    }),
  }),
  PrivateLabelCreditCard,
  Spotlight,
  ShopByCategory,
  VisualNavigation,
  PromoVisualNavigation
});

export const guestB2cClientSideDataModel = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
  }).shape({
    id: string(),
  })
});

export const homepagePreviewByIDDataModelServerSide = extend({
  layout: params({
    layoutClass: customType('LayoutClass').enum(['PersonalizedHomePage']),
    id: string().isRequired(),
  }).shape({
    PersonalizedHomePage: fragment().shape({
      id: string(),
      pageTheme: string(),
      // you need this here too because the query is shaped at a lower level
      // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
      heroCarousel: shape({
        id: string(),
        customRenderId: string(),
        isCarousel: bool(),
        isAutoplay: bool(),
        theme: string(),
        components: arrayOf(
          shape({
            ...Spotlight.dataModel.component,
            ...SpotlightContainer.dataModel.specializedContainer,
            ...ComponentSelector.dataModel.component,
            HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
          }),
        ),
      }),
      componentOrder: shape({
        PersonalizedHomeServices: fragment().shape(
          PersonalizedHomeServices.dataModel,
        ),
        PopularCategories: fragment().shape(
          PopularCategories.dataModel
        ),
        PrivateLabelCreditCard: fragment().shape(
          PrivateLabelCreditCard.dataModel,
        ),
        Recommendations: fragment().shape(Recommendations.dataModel),
        SpecialBuyOfTheDay: fragment().shape(SpecialBuyOfTheDay.dataModel),
        SpecialBuyOfTheWeek: fragment().shape(SpecialBuyOfTheWeek.dataModel),
        ...EventCountdownTimer.dataModel.component,
        Section: fragment().shape(SectionDM.dataModel),
        ...ShopByCategory.dataModel.component,
        SponsoredBanner: fragment().shape(SponsoredBanner.dataModel),
        OnlineWorkshops: fragment().shape(OnlineWorkshops.dataModel),
        RecentOrders: fragment().shape(RecentOrders.dataModel),
        SignIn: fragment().shape(SignIn.dataModel),
        ...Spotlight.dataModel.component,
      }),
      welcomeMessage: string(),
    }),
  }),
});

export const homepagePreviewByIDDataModelClientSide = extend({
  layout: params({
    layoutClass: customType('LayoutClass').enum(['PersonalizedHomePage']),
    id: string().isRequired(),
  }).shape({
    PersonalizedHomePage: fragment().shape({
      id: string(),
      pageTheme: string(),
      // you need this here too because the query is shaped at a lower level
      // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
      componentOrder: shape({
        PopularCategories: fragment().shape(PopularCategories.dataModel),
        ...EventCountdownTimer.dataModel.component,
        ...ShopByCategory.dataModel.component,
        ...Spotlight.dataModel.component,
        ...SpotlightContainer.dataModel.specializedContainer,
        Recommendations: fragment().shape(Recommendations.dataModel),
        SpecialBuyOfTheDay: fragment().shape(SpecialBuyOfTheDay.dataModel),
        SpecialBuyOfTheWeek: fragment().shape(SpecialBuyOfTheWeek.dataModel),
        Section: fragment().shape(SectionDM.dataModel),
        PersonalizedHomeServices: fragment().shape(
          PersonalizedHomeServices.dataModel,
        ),
        SponsoredBanner: fragment().shape(SponsoredBanner.dataModel),
        PromotionCard: fragment().shape(PromotionCard.dataModel),
        RecentPurchases: fragment().shape(RecentPurchases.dataModel),
        RecentOrders: fragment().shape(RecentOrders.dataModel),
        LoyaltyBenefits: fragment().shape(LoyaltyBenefits.dataModel),
        OnlineWorkshops: fragment().shape(OnlineWorkshops.dataModel),
        UserList: fragment().shape(UserList.dataModel),
        SignIn: fragment().shape(SignIn.dataModel),
      }),
    }),
  }),
  PrivateLabelCreditCard,
  Spotlight,
  SpotlightContainer,
  ShopByCategory,
  VisualNavigation,
  PromoVisualNavigation
});

export const proDataModelServerSide = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
  }).shape({
    id: string(),
    content: shape({
      __typename: string(),
      PersonalizedHomePage: fragment().shape({
        id: string(),
        pageTheme: string(),
        internalMarketingBanner: shape({ ...MedioInline.dataModel.component, ...PiccolaPencil.dataModel.component }),
        // you need this here too because the query is shaped at a lower level
        // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
        heroCarousel: shape({
          id: string(),
          customRenderId: string(),
          isCarousel: bool(),
          isAutoplay: bool(),
          theme: string(),
          components: arrayOf(
            shape({
              ...ComponentSelector.dataModel.component,
              HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
            }),
          ),
        }),
      }),
    }),
    seo: shape({
      title: string(),
      description: string(),
      facebookAdmins: string(),
      siteName: string(),
      type: string(),
      canonicalURL: string(),
      keywords: arrayOf(string()),
      no_index: bool(),
      no_follow: bool(),
    }),
  }),
});

export const proDataModelClientSide = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
  }).shape({
    id: string(),
    content: shape({
      __typename: string(),
      PersonalizedHomePage: fragment().shape({
        id: string(),
        pageTheme: string(),
        // you need this here too because the query is shaped at a lower level
        // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
        componentOrder: shape({
          ...EventCountdownTimer.dataModel.component,
          ...Spotlight.dataModel.component,
          ...ShopByCategory.dataModel.component,
          Recommendations: fragment().shape(Recommendations.dataModel),
          Section: fragment().shape(SectionDM.dataModel),
          SpecialBuyOfTheDay: fragment().shape(SpecialBuyOfTheDay.dataModel),
          SpecialBuyOfTheWeek: fragment().shape(SpecialBuyOfTheWeek.dataModel),
          SponsoredBanner: fragment().shape(SponsoredBanner.dataModel),
          PersonalizedHomeServices: fragment().shape(
            PersonalizedHomeServices.dataModel,
          ),
          PromotionCard: fragment().shape(PromotionCard.dataModel),
          RecentPurchases: fragment().shape(RecentPurchases.dataModel),
          LoyaltyBenefits: fragment().shape(LoyaltyBenefits.dataModel),
          UserList: fragment().shape(UserList.dataModel),
        }),
      }),
    }),
    seo: shape({
      title: string(),
      description: string(),
      facebookAdmins: string(),
      siteName: string(),
      type: string(),
      canonicalURL: string(),
      keywords: arrayOf(string()),
      no_index: bool(),
      no_follow: bool(),
    }),
  }),
  PrivateLabelCreditCard,
  Spotlight,
  VisualNavigation,
  PromoVisualNavigation
});

export const eprocurementDataModelServerSide = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
  }).shape({
    id: string(),
    content: shape({
      __typename: string(),
      PersonalizedHomePage: fragment().shape({
        id: string(),
        internalMarketingBanner: shape({ ...MedioInline.dataModel.component, ...PiccolaPencil.dataModel.component }),
        // you need this here too because the query is shaped at a lower level
        // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
        heroCarousel: shape({
          id: string(),
          customRenderId: string(),
          isCarousel: bool(),
          isAutoplay: bool(),
          theme: string(),
          components: arrayOf(
            shape({
              ...ComponentSelector.dataModel.component,
              HeroFlattenImage: fragment().shape(HeroFlattenImage.dataModel),
            }),
          ),
        }),
      }),
    }),
    seo: shape({
      title: string(),
      description: string(),
      facebookAdmins: string(),
      siteName: string(),
      type: string(),
      canonicalURL: string(),
      keywords: arrayOf(string()),
      no_index: bool(),
      no_follow: bool(),
    }),
  }),
});

export const eprocurementDataModelClientSide = extend({
  layouts: params({
    slug: string().isRequired(),
    customerType: string(),
  }).shape({
    id: string(),
    content: shape({
      __typename: string(),
      PersonalizedHomePage: fragment().shape({
        id: string(),
        // you need this here too because the query is shaped at a lower level
        // it doesnt magically know how to merge the two heroCarousels because the nesting is odd
        componentOrder: shape({
          ...EventCountdownTimer.dataModel.component,
          ...Spotlight.dataModel.component,
          ...ShopByCategory.dataModel.component,
          Recommendations: fragment().shape(Recommendations.dataModel),
          Section: fragment().shape(SectionDM.dataModel),
          SpecialBuyOfTheDay: fragment().shape(SpecialBuyOfTheDay.dataModel),
          SpecialBuyOfTheWeek: fragment().shape(SpecialBuyOfTheWeek.dataModel),
          SponsoredBanner: fragment().shape(SponsoredBanner.dataModel),
          PersonalizedHomeServices: fragment().shape(
            PersonalizedHomeServices.dataModel,
          ),
          PromotionCard: fragment().shape(PromotionCard.dataModel),
          RecentPurchases: fragment().shape(RecentPurchases.dataModel),
          LoyaltyBenefits: fragment().shape(LoyaltyBenefits.dataModel),
          UserList: fragment().shape(UserList.dataModel),
        }),
      }),
    }),
    seo: shape({
      title: string(),
      description: string(),
      facebookAdmins: string(),
      siteName: string(),
      type: string(),
      canonicalURL: string(),
      keywords: arrayOf(string()),
      no_index: bool(),
      no_follow: bool(),
    }),
  }),
  PrivateLabelCreditCard,
  Spotlight,
  VisualNavigation,
  PromoVisualNavigation
});
