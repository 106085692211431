import React, { useContext } from 'react';
import {
  CardTitle,
  Skeleton,
  SkeletonBlock,
  SkeletonLine,
  SkeletonContent,
  Typography,
  Button,
  CardMedia
} from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { func, bool } from 'prop-types';
import { getImageURL } from '../../../common/utils/CardUtils';
import { DisplayUtil } from '../../../common/utils/DisplayUtils';
import './WelcomeCardErrorPage.scss';
import '../../../common/style/app.scss';

const WelcomeCardErrorPage = (props) => {
  const { handleOnClick, cardLoaded } = props;

  const SkeletonLoader = () => {
    return (
      <div className="WelcomeCardErrorPage__welcomeErrorCard">
        <Skeleton orientation="horizontal" className="sui-col-span-6 sui-flex-row">
          <SkeletonContent disablePadding="x" initialSize="2/4">
            <SkeletonBlock aspect="wide" height={20} />
          </SkeletonContent>
          <SkeletonContent disablePadding="x" initialSize="2/4">
            <SkeletonLine numberOfLines={2} />
          </SkeletonContent>
        </Skeleton>
        <Skeleton disablePadding="y" className="sui-col-span-6 sui-pb-4">
          <SkeletonContent disablePadding="x">
            <SkeletonLine numberOfLines={3} />
          </SkeletonContent>
          <SkeletonContent disablePadding="x">
            <SkeletonLine numberOfLines={3} />
          </SkeletonContent>
        </Skeleton>
      </div>
    );
  };

  const MobileSkeletonLoader = () => {
    return (
      <div className="WelcomeCardErrorPage__welcomeErrorCard">
        <Skeleton orientation="horizontal" className="sui-col-span-6">
          <SkeletonContent disablePadding grow>
            <SkeletonLine numberOfLines={7} fullWidth />
          </SkeletonContent>
        </Skeleton>
      </div>
    );
  };

  const TabletSkeletonLoader = () => {
    return (
      <div className="WelcomeCardErrorPage__welcomeErrorCard">
        <Skeleton disablePadding="y" orientation="horizontal" className="sui-col-span-6 sui-pt-4">
          <SkeletonContent orientation="horizontal" disablePadding grow>
            <SkeletonBlock aspect="wide" height={8} />
            <SkeletonBlock aspect="wide" height={8} />
          </SkeletonContent>
        </Skeleton>
        <Skeleton disablePadding="y" orientation="horizontal" className="sui-col-span-6 sui-pb-6 sui-pt-9">
          <SkeletonContent grow disablePadding orientation="horizontal">
            <SkeletonContent density="loose" disablePadding initialSize="2/4">
              <SkeletonBlock height={14} aspect="wide" />
            </SkeletonContent>
            <SkeletonContent density="loose" disablePadding initialSize="2/4">
              <SkeletonBlock height={6} />
            </SkeletonContent>
          </SkeletonContent>
          <SkeletonContent grow disablePadding orientation="horizontal">
            <SkeletonContent density="loose" disablePadding initialSize="2/4">
              <SkeletonBlock height={14} aspect="wide" />
            </SkeletonContent>
            <SkeletonContent density="loose" disablePadding initialSize="2/4">
              <SkeletonBlock height={6} />
            </SkeletonContent>
          </SkeletonContent>
          <SkeletonContent grow disablePadding orientation="horizontal">
            <SkeletonContent density="loose" disablePadding initialSize="2/4">
              <SkeletonBlock height={14} aspect="wide" />
            </SkeletonContent>
            <SkeletonContent density="loose" disablePadding initialSize="2/4">
              <SkeletonBlock height={6} />
            </SkeletonContent>
          </SkeletonContent>
        </Skeleton>
      </div>
    );
  };

  if (!cardLoaded) {
    return (
      <DisplayUtil
        mobileView={<MobileSkeletonLoader />}
        tabletView={<TabletSkeletonLoader />}
        desktopView={<SkeletonLoader />}
      />
    );
  }

  const RefreshButton = () => {
    return (
      <div className="sui-flex sui-flex-col sui-w-full">
        <Button
          fullWidth
          variant="secondary"
          role="button"
          tag="a"
          onClick={handleOnClick}
        >
          Refresh
        </Button>
      </div>

    );
  };
  const CustomImage = () => {
    return (
      <img
        src={getImageURL('Pro-Xtra_Perk_Icon-whiteFill.svg')}
        alt="pro-extra-perk"
        height="56px"
        width="56px"
      />
    );
  };
  const RefreshLabel = () => {
    const { channel } = useContext(ExperienceContext);
    return (
      <div className="sui-flex sui-flex-col sui-gap-4 sui-items-center sui-text-center sui-m-auto sui-mt-28 sui-mb-28">
        <CardMedia
          component={CustomImage}
        />
        <Typography className="sui-mt-8 sui-mb-4" variant="h5" weight="bold" height="tight">
          Unable to Show Loyalty Status
        </Typography>
        <Typography variant="body-base" weight="regular" height="tight">
          Check back later or try refreshing to view your loyalty status
        </Typography>
      </div>
    );
  };

  return (
    <div
    // eslint-disable-next-line tailwindcss/no-arbitrary-value
      className="sui-flex sui-flex-col sui-h-full"
      data-type="container"
      data-component="LoyaltyBenefitsFallbackCard"
    >
      <CardTitle header={
        <Typography variant="h3">Pro Xtra</Typography>
      }
      />
      <RefreshLabel />
      <RefreshButton />
    </div>
  );
};

WelcomeCardErrorPage.propTypes = {
  handleOnClick: func,
  cardLoaded: bool,
};

WelcomeCardErrorPage.defaultProps = {
  handleOnClick: () => { },
  cardLoaded: true,
};

export { WelcomeCardErrorPage };
